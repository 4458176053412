<template>
  <div class="partner_id">
    <header>
      <div class="container">
        <div class="wrapper">
          <PuSkeleton v-if="loading" width="400px" height="40px" />
          <h1 v-else>{{ partner.brand || "Не задано" }}</h1>

          <PuSkeleton v-if="loading" width="400px" height="55px" />
          <a
            v-else
            :href="partnerWebsiteUrl"
            target="_blank"
            role="button"
            :class="{ 'p-button': true, 'disabled':  partner.website === null}"
          >
            {{ localize("button")["partnerSite"] }}
          </a>

          <PuSkeleton class="sm-block" v-if="loading" width="400px" height="55px" />
          <a
            v-else
            :class="{ 'sm-block': true, disabled: partner.website === null }"
            :href="partner.website"
            target="_blank"
          >
            {{ localize("button")["partnerSite"] }}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.35355 5.64645C9.15829 5.45118 8.84171 5.45118 8.64645 5.64645C8.45118 5.84171 8.45118 6.15829 8.64645 6.35355L9.35355 5.64645ZM14.2929 11.2929L13.9393 11.6464V11.6464L14.2929 11.2929ZM14.2929 12.7071L13.9393 12.3536V12.3536L14.2929 12.7071ZM8.64645 17.6464C8.45118 17.8417 8.45118 18.1583 8.64645 18.3536C8.84171 18.5488 9.15829 18.5488 9.35355 18.3536L8.64645 17.6464ZM8.64645 6.35355L13.9393 11.6464L14.6464 10.9393L9.35355 5.64645L8.64645 6.35355ZM13.9393 12.3536L8.64645 17.6464L9.35355 18.3536L14.6464 13.0607L13.9393 12.3536ZM13.9393 11.6464C14.1346 11.8417 14.1346 12.1583 13.9393 12.3536L14.6464 13.0607C15.2322 12.4749 15.2322 11.5251 14.6464 10.9393L13.9393 11.6464Z"
                :fill="partner.website === null ? scssVars.disabled : scssVars.main"
              />
            </svg>
          </a>
        </div>
      </div>
    </header>

    <section class="partner_info">
      <div class="container">
        <div class="row">
          <div class="col-xl-5 col-ml-5 text-center _image">
            <div class="_container">

              <PuSkeleton v-if="loading" height="295px"></PuSkeleton>

              <img
                v-else
                :src="partnerLogoUrl"
                alt="Partner Logo"
                width="340"
              />
            </div>
          </div>

          <div class="col-xl-7 col-ml-7">
            <h2>{{ localize("partnersList")["about"] }}</h2>

            <div v-if="loading">
              <PuSkeleton class="mb-8" height="25px"></PuSkeleton>
              <PuSkeleton class="mb-8" height="25px"></PuSkeleton>
              <PuSkeleton class="mb-8" height="25px"></PuSkeleton>
              <PuSkeleton class="mb-8" height="25px"></PuSkeleton>
            </div>

            <p v-else v-html="partner.description || partner.address"></p>

            <ul class="phone">
              <li>
                <img src="../assets/icons/phoneIcon.svg" alt="" />

                <div>
                  <PuSkeleton v-if="loading" height="50px"></PuSkeleton>
                  <a v-else href="javascript:">{{ partner.phone }}</a>
                </div>
              </li>
            </ul>

            <h3>{{ localize("partnersList")["productCategory"] }}</h3>

            <ul class="product">
              <li v-for="(name, index) in partners.categories" :key="index">
                <img src="../assets/icons/phoneIcon.svg" alt="" />
                <a href="javascript:">{{ name }}</a>
              </li>
            </ul>

          </div>
        </div>
      </div>

      <div v-if="loading" class="container">
        <PuSkeleton height="600px"></PuSkeleton>
      </div>

      <template v-else>
        <div v-if="partners.children.length > 0 || partner.lat !== null && partner.lon !== null" class="container">
        <span class="marks-title">
          {{ $t("map.label", { number: partners.children.length === 0 ? 1 : partners.children.length + 1 }) }}
        </span>

          <yandex-map
            :coords="coords"
            :settings="settings"
            style="width: 100%; height: 520px; padding-top: 0; padding-bottom: 0"
            :zoom="5"
            class="mt-40"
          >
            <!-- marker of main office -->
            <ymap-marker
              :coords="[partner.lat, partner.lon]"
              :hint-content="partner.address"
              :icon="mapIcon"
              :marker-id="partner.id"
            />

            <!-- markers of filials -->
            <ymap-marker
              v-for="child in partners.children"
              :key="child.id"
              :coords="[child.lat, child.lon]"
              :icon="mapIcon"
              :marker-id="child.id"
              :hint-content="child.address"
            />
          </yandex-map>
        </div>
      </template>
    </section>
  </div>
</template>

<script>
import VueTypes from "vue-types";
import localize from "../filters/localize.filter";
import { yandexMap, ymapMarker } from "vue-yandex-maps";
import config from "../config";
import { scssVars } from "@/utils/constants";

import markerIcon from "../assets/icons/location.svg";
import imagePlaceholder from "@/assets/img/image-placeholder.png";

export default {
  name: "PartnersPage",
  props: {
    partnerId: VueTypes.any
  },
  components: {
    yandexMap,
    ymapMarker
  },
  data() {
    return {
      scssVars,
      partners: {
        children: [],
        categories: localize("partnersList")["categories"]
      },
      mapIcon: {
        imageHref: markerIcon,
        imageSize: [48, 48],
        imageOffset: [-24, -48]
      },
      partner: null,
      defaultLogo: imagePlaceholder,
      settings: {
        apiKey: config.ymapApiKey,
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1"
      },
      coords: [42, 70],
      loading: false
    };
  },
  computed: {
    partnerWebsiteUrl() {
      if (this.partner !== null && this.partner.website !== null) {
        const url = this.partner.website;

        const hasPrefixHTTP = /https/.test(url) || /http/.test(url);

        if (!hasPrefixHTTP) {
          return `https://${url}`;
        }
      }

      return this.partner.website;
    },
    partnerLogoUrl () {
      if (!this.partner.logo) return this.defaultLogo

      return `${process.env.VUE_APP_STORAGE_DOMAIN}/storage/${this.partner.logo.path}`
    }
  },
  async created() {
    this.loading = true;
    try {
      const { data: partner } = await this.$axios.post(`partners/detail/${this.partnerId}`);

      // fetch all partners
      const { data: { data: partners } } = await this.$axios.get("partners/list");

      this.partners.children = partners.filter(({ parent_id }) => parent_id == this.partnerId);

      this.loading = false;

      if (partner.status === "success") {
        this.partner = partner.data;
      } else {
        partner.data.response.errors.forEach((error) => this.$toastError(error));
      }

    } catch (e) {
      this.$toastError(e.message);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    localize,
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/mixins/font.scss";

.map-class {
  width: 100%;
  height: 600px;
}

.partner_id {
  //min-width: 100vh;

  header {
    text-align: center;
    padding: 56px 0;
    background: $grey url('../assets/icons/Vector.svg') no-repeat calc(50% - 120px) 37px;

    @media (max-width: $mobile) {
      padding: 56px 0 32px 0;
    }

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1 {
        @include font(40px, 900, 40px, $black);

        @media (max-width: $mobile) {
          @include font(20px, 900, 20px, $black);
        }
      }
    }
  }

  .partner_info {
    ._image {
      padding-top: 40px;

      ._container {
        padding: 70px 74px;

        img {
          mix-blend-mode: multiply;
        }
      }
    }

    .marks-title {
      font-size: 40px;
      font-weight: 900;

      @media (max-width: $mobile) {
        font-size: 20px;
      }
    }

    .ymap-container {
      margin-top: 40px;

      @media (max-width: $mobile) {
        margin-top: 16px;
      }
    }
  }
}

a.p-button {
  background: $main;
  box-shadow: -12px 12px 30px rgba(102, 16, 245, 0.25);
  border-radius: 8px;
  width: auto;
  padding: 18px 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 111.63%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  border: none;
  transition: 0.2s;

  &.disabled {
    background: $disabled;
    cursor: not-allowed;
    box-shadow: unset;
  }
}

section {
  padding-top: 140px;
  padding-bottom: 120px;
  background: $white;

  h2 {
    font-weight: 900;
    font-size: 40px;
    line-height: 40px;
    color: $black;
    margin-bottom: 16px;
  }

  h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: $black;
    margin-bottom: 12px;
  }

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: $black;
    margin-bottom: 32px;
  }

  ul.phone {
    margin-bottom: 32px;

    li {
      display: flex;
      align-items: center;

      div {
        display: flex;
        flex-direction: column;
        margin-left: 8px;
      }

      img {
        margin-top: 4px;
      }

      a {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: $black;
      }
    }
  }

  ul.product {
    display: flex;

    li {
      display: flex;
      align-items: center;
      margin-right: 16px;

      img {
        margin-right: 8px;
      }

      a {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: $black;
      }
    }
  }
}

.sm-block {
  display: none;
}

@media (max-width: 575px) {
  a.p-button {
    display: none !important;
  }
  .sm-block {
    display: flex;
    font-weight: 600;
    font-size: 14px;
    line-height: 111.63%;
    display: flex;
    align-items: center;
    color: $main;

    &.disabled {
      color: $disabled;
    }
  }
  header {
    padding: 21px 0;

    h1 {
      font-weight: 900;
      font-size: 24px;
      line-height: 30px;
      color: $black;
    }
  }
  section {
    padding-top: 16px;
    padding-bottom: 80px;

    h2 {
      font-weight: 900;
      font-size: 24px;
      line-height: 30px;
      color: $black;
      margin-bottom: 8px;
    }

    p {
      font-weight: normal;
      font-size: 13px;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: $black;
      margin-bottom: 16px;
    }

    h3 {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: $black;
    }

    ul.product {
      flex-wrap: wrap;
      flex-direction: column;
      gap: 10px 0;
      margin-bottom: 8px;
    }
  }
}
</style>
